import './App.css';
import Songs from './data.json';
import {useState} from "react";
import {soundex} from 'soundex-code'

function App() {
    const [searchTerm, setSearchTerm] = useState("");
    var parms ;

    try {
        if (searchTerm === "") {
            parms = Songs;
        } else {
            parms = Songs.filter((val) => {
                let originalString = searchTerm.trim();
                let words = originalString.split(" "); // split the string into an array of words
                let modifiedWords = words.map(word => soundex(word)); // apply the soundex function to each word
                let modifiedString = modifiedWords.join(" "); // join the modified words back into a string

                if (val.name.toLowerCase().includes(searchTerm.toLowerCase()) || val.soundex.includes(modifiedString)) {
                    return val;
                }
            });
        }
    } catch (error) {
        alert("Invalid regular expression: " + error);
        parms = Songs;
    }

    return (
        <div className="App">
            <br/>
            <br/>
            <div style={{marginLeft:"20px", marginRight:"20px"}}>
                <input type="search" id="search" className="search" placeholder="Search Songs (using soundex algorithm)" onChange={(event) => setSearchTerm(event.target.value)}/>
                <table>
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Song Title</th>
                        <th scope="col">Song Book</th>
                        <th scope="col">Open</th>
                    </tr>
                    </thead>
                    <tbody>

                    {parms.map((item) => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>{item.book}</td>
                            <td>
                                <button onClick={ () => {
                                    window.open("https://songsofzion.org/songs/" + item.id);
                                }}>Open</button>
                            </td>
                        </tr>
                    ))}

                    </tbody>
                    <tfoot>
                    <tr>End of Data</tr>
                    </tfoot>
                </table>
            </div>

        </div>
    );
}

export default App;